import React from 'react';
import PropTypes from 'prop-types';
import searchUtil from '../util/searchUtil';
import { AutocompleteSearchLink, SearchLink } from './SearchLink';

function NewSearchLinks({ translate, searchInput, indexOfSelectedOption, searchSuggestions }) {
  return searchSuggestions.map((suggestion, index) => {
    const selected = parseInt(index, 10) === indexOfSelectedOption;
    return searchUtil.isAutocompleteSuggestion(suggestion) ? (
      <AutocompleteSearchLink
        key={suggestion.searchQuery}
        translate={translate}
        selected={selected}
        suggestion={suggestion}
      />
    ) : (
      <SearchLink
        key={suggestion.url}
        translate={translate}
        selected={selected}
        suggestion={suggestion}
        searchInput={searchInput}
      />
    );
  });
}

NewSearchLinks.propTypes = {
  translate: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  indexOfSelectedOption: PropTypes.number.isRequired,
  searchSuggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired,
        universeId: PropTypes.number.isRequired,
        canonicalTitle: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        searchQuery: PropTypes.string.isRequired,
        trendingSearchStartDateTime: PropTypes.string
      }),
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        pageSort: PropTypes.arrayOf(PropTypes.string).isRequired,
        icon: PropTypes.string.isRequired
      })
    ])
  ).isRequired
};

export default NewSearchLinks;
