import { eventStreamService } from 'core-roblox-utilities';
import { v4 as uuidv4 } from 'uuid';

const { eventTypes } = eventStreamService;
const searchAutocompleteContext = 'searchAutocomplete';
const actionTypes = { open: 'open', submit: 'submit', close: 'close' };
const generateSessionInfo = uuidv4;

const eventStreamCriterias = {
  actionTypes,
  generateSessionInfo,
  searchTextTrim: (kwd, resultingKwd, searchType, sessionInfo) => ({
    name: 'searchTextTrim',
    type: eventTypes.formInteraction,
    context: searchAutocompleteContext,
    params: {
      kwd,
      resultingKwd,
      searchType,
      sessionInfo
    }
  }),
  searchClear: (kwd, searchType, sessionInfo) => ({
    name: 'searchClear',
    type: eventTypes.formInteraction,
    context: searchAutocompleteContext,
    params: {
      kwd,
      searchType,
      sessionInfo
    }
  }),
  searchSuggestionClicked: (
    kwd,
    searchType,
    suggestionPosition,
    suggestionClicked,
    suggestionType,
    suggestions,
    sessionInfo
  ) => ({
    name: 'searchSuggestionClicked',
    type: eventTypes.formInteraction,
    context: searchAutocompleteContext,
    params: {
      kwd,
      searchType,
      suggestionPosition,
      suggestionClicked,
      suggestionType,
      suggestions,
      sessionInfo
    }
  }),
  searchAutocomplete: (
    kwd,
    resultingKwd,
    previousKwd,
    latency,
    fromLocalCache,
    algorithm,
    timeoutDelayMs,
    sessionInfo
  ) => ({
    name: 'searchAutocomplete',
    type: eventTypes.formInteraction,
    context: searchAutocompleteContext,
    params: {
      kwd,
      resultingKwd,
      previousKwd,
      latency,
      fromLocalCache,
      algorithm,
      timeoutDelayMs,
      sessionInfo
    }
  }),
  search: (kwd, actionType, sessionInfo) => ({
    name: 'search',
    type: eventTypes.formInteraction,
    context: searchAutocompleteContext,
    params: {
      kwd,
      actionType,
      sessionInfo
    }
  })
};

export { eventStreamCriterias as default };
