import React from 'react';
import { Popover } from 'react-style-guide';
import NotificationStreamIcon from '../containers/NotificationStreamIcon';
import NotificationStreamBase from '../containers/NotificationStreamBase';

function NotificationStreamPopover() {
  return (
    <Popover
      id='notification-stream-popover'
      trigger='click'
      placement='bottom'
      closeOnClick={false}
      icon={
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li id='navbar-stream' className='navbar-icon-item navbar-stream'>
          <NotificationStreamIcon />
        </li>
      }
      role='menu'>
      <NotificationStreamBase />
    </Popover>
  );
}

NotificationStreamPopover.propTypes = {};

export default NotificationStreamPopover;
