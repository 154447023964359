import React from 'react';
import PropTypes from 'prop-types';

function UniverseSearchIcon({ toggleUniverseSearch }) {
  return (
    <li
      className='cursor-pointer rbx-navbar-right-search'
      onClick={toggleUniverseSearch}
      onKeyPress={toggleUniverseSearch}
      role='menuitem'>
      <span className='rbx-menu-icon rbx-menu-item'>
        <span className='icon-nav-search-white' />
      </span>
    </li>
  );
}

UniverseSearchIcon.propTypes = {
  toggleUniverseSearch: PropTypes.func.isRequired
};
export default UniverseSearchIcon;
