import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from 'react-utilities';
import { httpService } from 'core-utilities';
import { eventStreamService } from 'core-roblox-utilities';
import { translationConfig } from '../translation.config';
import SearchInput from '../components/SearchInput';
import layout from '../constants/layoutConstants';
import search from '../constants/searchConstants';
import searchService from '../services/searchService';
import events from '../constants/searchAutocompleteEventStreamConstants';
import navigationUtil from '../util/navigationUtil';
import searchUtil from '../util/searchUtil';
import useDebounce from '../hooks/useDebounce';

export function UniversalSearch({ translate, isUniverseSearchShown }) {
  const [searchInput, setSearchInput] = useState(
    navigationUtil.parseQuery(window.location.search).keyword || ''
  );
  const debouncedSearchInput = useDebounce(searchInput, search.debounceTimeout);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState(null);
  const [autocompleteSessionInfo, setAutocompleteSessionInfo] = useState(
    events.generateSessionInfo()
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuHover, setIsMenuHover] = useState(false);
  const [indexOfSelectedOption, setSelectedListOptions] = useState(0);
  const { keyCodes } = layout;

  const universalSearchLinks = search.isAutocompleteSuggestionsIXPTestEnabled
    ? navigationUtil.getNewUniversalSearchLinks()
    : navigationUtil.getUniversalSearchLinks();
  const gameSearchLinkIndex = universalSearchLinks.findIndex(
    ({ label }) => label === 'Label.sGames'
  );
  const searchSuggestions = useMemo(() => {
    if (autocompleteSuggestions) {
      const splicedSuggestions = autocompleteSuggestions.slice(
        0,
        search.numberOfAutocompleteSuggestions
      );
      return [
        ...universalSearchLinks.slice(0, gameSearchLinkIndex + 1),
        ...splicedSuggestions,
        ...universalSearchLinks.slice(gameSearchLinkIndex + 1)
      ];
    }
    return universalSearchLinks;
  }, [autocompleteSuggestions, universalSearchLinks]);
  const searchSuggestionLength = searchSuggestions.length;

  useEffect(() => {
    eventStreamService.sendEvent(
      events.search(searchInput, events.actionTypes.submit, autocompleteSessionInfo)
    );
    const getAutocompleteSuggestion = async () => {
      if (search.isAutocompleteSuggestionsIXPTestEnabled) {
        setAutocompleteSuggestions(null);

        try {
          const data = await searchService.postRequestSuggestion(debouncedSearchInput);
          setAutocompleteSuggestions(data.entries);
        } catch (error) {
          if (!httpService.isCancelled(error)) {
            setAutocompleteSuggestions([]);
          }
        }
      }
    };

    getAutocompleteSuggestion();
  }, [debouncedSearchInput]);

  const handleSearch = ({ target: { value } }) => {
    if (value.length < searchInput.length) {
      eventStreamService.sendEvent(
        events.searchTextTrim(searchInput, value, undefined, autocompleteSessionInfo)
      );
    }
    setSelectedListOptions(0);
    setIsMenuOpen(value.length > 0);
    setSearchInput(value);
  };

  const resetAutocompleteSessionInfo = () => {
    setAutocompleteSessionInfo(events.generateSessionInfo());
  };

  const toggleMenu = () => {
    const newState = !isMenuOpen;

    if (newState) {
      const newAutoCompleteSessionInfo = events.generateSessionInfo();
      eventStreamService.sendEvent(
        events.search(searchInput, events.actionTypes.open, newAutoCompleteSessionInfo)
      );
      setAutocompleteSessionInfo(newAutoCompleteSessionInfo);
    } else {
      eventStreamService.sendEvent(
        events.search(searchInput, events.actionTypes.close, autocompleteSessionInfo)
      );
    }

    if ((newState && searchInput.length === 0) || isMenuHover) return;
    setIsMenuOpen(newState);
  };

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onKeyDown = e => {
    let currentCursor = indexOfSelectedOption;
    if (
      e.keyCode === keyCodes.arrowUp ||
      e.keyCode === keyCodes.arrowDown ||
      e.keyCode === keyCodes.tab
    ) {
      e.stopPropagation();
      e.preventDefault();
      if (e.keyCode === keyCodes.arrowUp) {
        currentCursor -= 1;
      } else {
        currentCursor += 1;
      }

      currentCursor %= searchSuggestionLength;
      if (currentCursor < 0) {
        currentCursor = searchSuggestionLength + currentCursor;
      }
      setSelectedListOptions(currentCursor);
    }
  };

  const onKeyUp = e => {
    if (e.keyCode === keyCodes.enter) {
      e.stopPropagation();
      e.preventDefault();

      const suggestion = searchSuggestions[indexOfSelectedOption];
      const suggestionUrl = searchUtil.getSuggestionUrl(suggestion, e);
      if (suggestionUrl) {
        window.location = suggestionUrl;
      }
    }
  };

  return (
    <SearchInput
      {...{
        searchInput,
        handleSearch,
        toggleMenu,
        setIsMenuHover,
        isMenuOpen,
        indexOfSelectedOption,
        onSubmit,
        onKeyDown,
        onKeyUp,
        isUniverseSearchShown,
        translate,
        searchSuggestions,
        autocompleteSuggestions,
        autocompleteSessionInfo,
        resetAutocompleteSessionInfo
      }}
    />
  );
}

UniversalSearch.defaultProps = {
  isUniverseSearchShown: true
};

UniversalSearch.propTypes = {
  translate: PropTypes.func.isRequired,
  isUniverseSearchShown: PropTypes.bool
};

export const UniversalSearchContainer = withTranslations(UniversalSearch, translationConfig);
