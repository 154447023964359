import React from 'react';

import layoutConstant from '../constants/layoutConstants';

const { headerMenuIconClickEvent } = layoutConstant;

function MenuIcon() {
  const onClickMenuIcon = () => {
    document.dispatchEvent(new CustomEvent(headerMenuIconClickEvent.name));
  };

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <span onClick={onClickMenuIcon} onKeyPress={onClickMenuIcon} className='icon-nav-menu' />;
}

MenuIcon.propTypes = {};

export default MenuIcon;
