import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-style-guide';
import BuyRobuxIcon from './BuyRobuxIcon';
import RobuxMenu from './RobuxMenu';
import navigationUtil from '../util/navigationUtil';

function BuyRobuxPopover({ translate, robuxAmount, isGetCurrencyCallDone, robuxError }) {
  return (
    <Popover
      id='buy-robux-popover'
      trigger='click'
      placement='bottom'
      icon={
        <li id='navbar-robux' className='cursor-pointer navbar-icon-item'>
          <BuyRobuxIcon {...{ robuxAmount, isGetCurrencyCallDone, robuxError }} />
        </li>
      }
      role='menu'>
      <div className={navigationUtil.getThemeClass()}>
        <ul id='buy-robux-popover-menu' className='dropdown-menu'>
          <RobuxMenu {...{ translate, robuxAmount, isGetCurrencyCallDone, robuxError }} />
        </ul>
      </div>
    </Popover>
  );
}

BuyRobuxPopover.defaultProps = {
  robuxAmount: 0,
  robuxError: ''
};

BuyRobuxPopover.propTypes = {
  translate: PropTypes.func.isRequired,
  robuxAmount: PropTypes.number,
  robuxError: PropTypes.string,
  isGetCurrencyCallDone: PropTypes.bool.isRequired
};

export default BuyRobuxPopover;
