import layoutConstants from '../constants/layoutConstants';
import links from '../constants/linkConstants';

const isAutocompleteSuggestion = suggestion => suggestion?.label === undefined;

const getSuggestionUrl = (suggestion, event) => {
  const { target } = event;
  if (isAutocompleteSuggestion(suggestion)) {
    return links.gameSearchLink.url + encodeURIComponent(suggestion.searchQuery);
  }
  if (target && target.value && target.value.length > layoutConstants.minimumSearchLength) {
    return suggestion.url + encodeURIComponent(target.value);
  }

  return '';
};

export default {
  isAutocompleteSuggestion,
  getSuggestionUrl
};
