import { EnvironmentUrls } from 'Roblox';

const { apiGatewayUrl } = EnvironmentUrls;

export default {
  debounceTimeout: 100,
  expiryTimeout: 300,
  variationId: 4,
  trendingVariationId: 0,
  requestSuggestionUrl: {
    url: `${apiGatewayUrl}/games-autocomplete/v1/request-suggestion`,
    withCredentials: true
  },
  isAutocompleteSuggestionsIXPTestEnabled:
    parseInt(
      document.getElementById('navigation-container')?.dataset.numberOfAutocompleteSuggestions,
      10
    ) > 0,
  numberOfAutocompleteSuggestions:
    document.getElementById('navigation-container')?.dataset.numberOfAutocompleteSuggestions || 0
};
