import React from 'react';
import angular from 'angular';

class LoginForm extends React.Component {
  constructor() {
    super();
    // TODO: double check these values are true across environments
    this.html = `
            <div login-form
                context='loginDropdown'
                is-facebook-sign-in-enabled='true'
                use-facebook-redirect='true'
                redirect-to-login-page-when-username-is-required='true'
            ></div>`;
  }

  componentDidMount() {
    try {
      angular.module('login');
      angular.bootstrap(this.container, ['login', 'loginAppTemplates']);
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div
        id='login-container'
        className='login-container'
        ref={c => {
          this.container = c;
        }}
        dangerouslySetInnerHTML={{
          __html: this.html
        }}
      />
    );
  }
}

export default LoginForm;
