import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-style-guide';
import SettingsIcon from './SettingsIcon';
import SettingsMenu from './SettingsMenu';
import navigationUtil from '../util/navigationUtil';
import navigationService from '../services/navigationService';

function SettingsPopover({ translate, accountNotificationCount }) {
  const [
    isCrossDeviceLoginCodeValidationDisplayed,
    setCrossDeviceLoginCodeValidationDisplayed
  ] = useState(false);
  useEffect(() => {
    setCrossDeviceLoginCodeValidationDisplayed(false);
    navigationService.getAuthTokenMetadata().then(
      data => {
        if (data?.IsCodeValidationDisplayed) {
          setCrossDeviceLoginCodeValidationDisplayed(true);
        }
      },
      e => {
        console.debug('e: ', e);
      }
    );
  }, []);
  return (
    <Popover
      id='settings-popover'
      trigger='click'
      placement='bottom'
      className={navigationUtil.getThemeClass()}
      containerPadding={20}
      icon={
        <li id='navbar-settings' className='cursor-pointer navbar-icon-item'>
          <SettingsIcon accountNotificationCount={accountNotificationCount} />
        </li>
      }
      role='menu'>
      <div className={navigationUtil.getThemeClass()}>
        <ul id='settings-popover-menu' className='dropdown-menu'>
          <SettingsMenu
            isCrossDeviceLoginCodeValidationDisplayed={isCrossDeviceLoginCodeValidationDisplayed}
            translate={translate}
            accountNotificationCount={accountNotificationCount}
          />
        </ul>
      </div>
    </Popover>
  );
}

SettingsPopover.defaultProps = {
  accountNotificationCount: 0
};

SettingsPopover.propTypes = {
  translate: PropTypes.func.isRequired,
  accountNotificationCount: PropTypes.number
};

export default SettingsPopover;
