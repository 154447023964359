import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { eventStreamService } from 'core-roblox-utilities';
import { Link } from 'react-style-guide';
import navigationUtil from '../util/navigationUtil';
import events from '../constants/searchAutocompleteEventStreamConstants';

function SearchLinks({ translate, searchInput, indexOfSelectedOption, autocompleteSessionInfo }) {
  const universalSearchLinks = navigationUtil.getUniversalSearchLinks();
  let locationLabel;
  return (
    <React.Fragment>
      {Object.entries(universalSearchLinks).map(([key, { url, label }]) => {
        locationLabel = label;
        if (locationLabel === 'Label.sCatalog') {
          locationLabel = 'Label.AvatarShop';
        }

        const listClass = ClassNames(
          'navbar-search-option rbx-clickable-li',
          parseInt(key, 10) === indexOfSelectedOption ? ' selected' : ''
        );
        return (
          <li key={key} className={listClass}>
            <Link
              className='navbar-search-anchor'
              url={url + searchInput}
              onClick={() =>
                eventStreamService.sendEvent(
                  events.searchSuggestionClicked(
                    searchInput,
                    undefined,
                    key,
                    translate(locationLabel),
                    undefined,
                    undefined,
                    autocompleteSessionInfo
                  )
                )
              }>
              {translate('Label.sSearchPhrase', {
                phrase: searchInput,
                location: translate(locationLabel)
              })}
            </Link>
          </li>
        );
      })}
    </React.Fragment>
  );
}

SearchLinks.propTypes = {
  translate: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  indexOfSelectedOption: PropTypes.number.isRequired,
  autocompleteSessionInfo: PropTypes.string.isRequired
};

export default SearchLinks;
