import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { eventStreamService } from 'core-roblox-utilities';
import SearchLinks from './SearchLinks';
import events from '../constants/searchAutocompleteEventStreamConstants';
import searchConstants from '../constants/searchConstants';
import NewSearchLinks from './NewSearchLinks';

function SearchInput({
  translate,
  searchInput,
  isMenuOpen,
  toggleMenu,
  handleSearch,
  setIsMenuHover,
  indexOfSelectedOption,
  onSubmit,
  onKeyDown,
  onKeyUp,
  isUniverseSearchShown,
  searchSuggestions,
  autocompleteSessionInfo,
  resetAutocompleteSessionInfo
}) {
  const inputRef = createRef();
  const clearSearch = () => {
    eventStreamService.sendEvent(
      events.searchClear(searchInput, undefined, autocompleteSessionInfo)
    );
    resetAutocompleteSessionInfo();
    inputRef?.current?.focus();
    handleSearch({ target: { value: '' } });
  };
  const menuClassName = classNames(
    'navbar-left navbar-search col-xs-5 col-sm-6 col-md-2 col-lg-3',
    {
      'navbar-search-open': isMenuOpen,
      shown: isUniverseSearchShown
    }
  );
  return (
    <div id='navbar-universal-search' className={menuClassName} role='search'>
      <div className='input-group'>
        <form onSubmit={onSubmit}>
          {searchConstants.isAutocompleteSuggestionsIXPTestEnabled ? (
            <div className='form-has-feedback'>
              <input
                ref={inputRef}
                id='navbar-search-input'
                className='form-control input-field new-input-field'
                type='text'
                value={searchInput}
                onChange={handleSearch}
                placeholder={translate('Label.sSearch')}
                maxLength='120'
                onFocus={toggleMenu}
                onBlur={toggleMenu}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                autoComplete='off'
              />
              {searchInput.length > 0 && (
                <span
                  id='navbar-search-clear-btn'
                  tabIndex={0}
                  role='button'
                  aria-label='Clear Search'
                  onClick={clearSearch}
                  onKeyDown={clearSearch}
                  className='clear-search icon-close-gray-16x16'
                />
              )}
            </div>
          ) : (
            <input
              id='navbar-search-input'
              className='form-control input-field'
              type='text'
              value={searchInput}
              onChange={handleSearch}
              placeholder={translate('Label.sSearch')}
              maxLength='120'
              onFocus={toggleMenu}
              onBlur={toggleMenu}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              autoComplete='off'
            />
          )}
        </form>
        <div className='input-group-btn'>
          <button id='navbar-search-btn' className='input-addon-btn' type='submit'>
            <span className='icon-nav-search' />
          </button>
        </div>
      </div>
      <ul
        className={classNames('dropdown-menu', {
          'new-dropdown-menu': searchConstants.isAutocompleteSuggestionsIXPTestEnabled
        })}
        role='menu'
        onMouseEnter={() => {
          setIsMenuHover(true);
        }}
        onMouseLeave={() => {
          setIsMenuHover(false);
        }}>
        {searchConstants.isAutocompleteSuggestionsIXPTestEnabled ? (
          <NewSearchLinks
            translate={translate}
            searchInput={searchInput}
            indexOfSelectedOption={indexOfSelectedOption}
            searchSuggestions={searchSuggestions}
          />
        ) : (
          <SearchLinks
            translate={translate}
            searchInput={searchInput}
            indexOfSelectedOption={indexOfSelectedOption}
            autocompleteSessionInfo={autocompleteSessionInfo}
          />
        )}
      </ul>
    </div>
  );
}

SearchInput.defaultProps = {
  isUniverseSearchShown: true
};

SearchInput.propTypes = {
  translate: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setIsMenuHover: PropTypes.func.isRequired,
  indexOfSelectedOption: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  isUniverseSearchShown: PropTypes.bool,
  searchSuggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired,
        universeId: PropTypes.number.isRequired,
        canonicalTitle: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        searchQuery: PropTypes.string.isRequired,
        trendingSearchStartDateTime: PropTypes.string
      }),
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        pageSort: PropTypes.arrayOf(PropTypes.string).isRequired,
        icon: PropTypes.string
      })
    ])
  ).isRequired,
  autocompleteSessionInfo: PropTypes.string.isRequired,
  resetAutocompleteSessionInfo: PropTypes.func.isRequired
};

export default SearchInput;
