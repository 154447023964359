import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-style-guide';
import {
  Thumbnail2d,
  ThumbnailTypes,
  DefaultThumbnailSize,
  ThumbnailFormat
} from 'roblox-thumbnails';
import { GamesAutocompleteSuggestionEntryType } from '../services/searchService';
import links from '../constants/linkConstants';

const { gameSearchLink } = links;

export function AutocompleteSearchLink({ translate, selected, suggestion }) {
  const listClass = ClassNames('navbar-search-option rbx-clickable-li', {
    'new-selected': selected
  });
  const { type, universeId, searchQuery } = suggestion;

  if (type === GamesAutocompleteSuggestionEntryType.GameSuggestion) {
    return (
      <li className={listClass}>
        <Link
          className='new-navbar-search-anchor navbar-search-anchor-with-thumbnail'
          url={gameSearchLink.url + searchQuery}>
          <span className={ClassNames(gameSearchLink.icon, 'navbar-list-option-icon')} />
          <span className='navbar-list-option-text'>{searchQuery}</span>
          <span className='navbar-list-option-suffix'>
            {translate('Label.sSearchPhraseV2', {
              location: translate(gameSearchLink.label)
            })}
          </span>
          <span className='navbar-list-option-thumbnail'>
            <span className='background-icon' />
            <Thumbnail2d
              type={ThumbnailTypes.gameIcon}
              size={DefaultThumbnailSize}
              targetId={universeId}
              containerClass='thumbnail-icon'
              format={ThumbnailFormat.jpeg}
            />
          </span>
        </Link>
      </li>
    );
  }

  return (
    <li className={listClass}>
      <Link className='new-navbar-search-anchor' url={gameSearchLink.url + searchQuery}>
        <span className={ClassNames(gameSearchLink.icon, 'navbar-list-option-icon')} />
        <span className='navbar-list-option-text'>{searchQuery}</span>
        <span className='navbar-list-option-suffix'>
          {translate('Label.sSearchPhraseV2', {
            location: translate(gameSearchLink.label)
          })}
        </span>
      </Link>
    </li>
  );
}

AutocompleteSearchLink.propTypes = {
  translate: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  suggestion: PropTypes.shape({
    type: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
    universeId: PropTypes.number,
    canonicalTitle: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    searchQuery: PropTypes.string.isRequired,
    trendingSearchStartDateTime: PropTypes.string
  }).isRequired
};

export function SearchLink({ translate, selected, searchInput, suggestion }) {
  const { url, label, icon } = suggestion;
  const listClass = ClassNames('navbar-search-option rbx-clickable-li', {
    'new-selected': selected
  });
  return (
    <li className={listClass}>
      <Link className='new-navbar-search-anchor' url={url + searchInput}>
        <span className={ClassNames(icon, 'navbar-list-option-icon')} />
        <span className='navbar-list-option-text'>{searchInput}</span>
        <span className='navbar-list-option-suffix'>
          {translate('Label.sSearchPhraseV2', {
            location: translate(label)
          })}
        </span>
      </Link>
    </li>
  );
}

SearchLink.propTypes = {
  translate: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  searchInput: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    pageSort: PropTypes.arrayOf(PropTypes.string).isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired
};
