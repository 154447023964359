import React from 'react';
import PropTypes from 'prop-types';
import { authenticatedUser } from 'header-scripts';
import { Popover } from 'react-style-guide';
import LoginForm from '../containers/LoginForm';
import HeaderIconsGroup from '../containers/HeaderIconsGroup';
import AgeBracketDisplay from './AgeBracketDisplay';
import navigationUtil from '../util/navigationUtil';
import UniverseSearchIcon from './UniverseSearchIcon';
import HeaderSignupLink from './HeaderSignupLink';

const { isLoginPopoverAvailable } = navigationUtil;

function HeaderRightNav({ translate, toggleUniverseSearch, ...props }) {
  const { isAuthenticated } = authenticatedUser;
  if (isAuthenticated) {
    return (
      <div className='navbar-right rbx-navbar-right'>
        <HeaderIconsGroup
          translate={translate}
          toggleUniverseSearch={toggleUniverseSearch}
          {...props}
        />
        <AgeBracketDisplay />
      </div>
    );
  }

  return (
    <div className='navbar-right rbx-navbar-right'>
      <ul className='nav navbar-right rbx-navbar-right-nav'>
        <HeaderSignupLink translate={translate} />
        <li className='login-action'>
          {isLoginPopoverAvailable() && (
            <Popover
              id='login-popover'
              trigger='click'
              placement='bottom'
              closeOnClick={false}
              icon={
                <span>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className='font-header-2 rbx-navbar-login nav-menu-title rbx-menu-item'>
                    {translate('Label.sLogin')}
                  </a>
                </span>
              }
              role='menu'>
              <LoginForm />
            </Popover>
          )}
        </li>
        <UniverseSearchIcon translate={translate} toggleUniverseSearch={toggleUniverseSearch} />
      </ul>
    </div>
  );
}

HeaderRightNav.propTypes = {
  translate: PropTypes.func.isRequired,
  toggleUniverseSearch: PropTypes.func.isRequired
};

export default HeaderRightNav;
