import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link, ScrollBar } from 'react-style-guide';
import links from '../constants/linkConstants';
import ScrollListContainer from '../containers/ScrollListContainer';
import userUtil from '../util/userUtil';

function LeftNavigation({ isLeftNavOpen, ...props }) {
  const classNames = ClassNames('rbx-left-col', {
    'nav-show': isLeftNavOpen
  });
  return (
    <div id='navigation' className={classNames}>
      <ul>
        <li key='username' className='text-lead nav-username'>
          <Link
            url={links.scrollListItems.profile.url}
            className='text-nav font-header-2 text-overflow'>
            {userUtil.nameForDisplay}
          </Link>
        </li>
        <li key='divider' className='rbx-divider' />
      </ul>
      <ScrollBar className='rbx-scrollbar'>
        <ScrollListContainer {...props} />
      </ScrollBar>
    </div>
  );
}

LeftNavigation.propTypes = {
  isLeftNavOpen: PropTypes.bool.isRequired
};

export default LeftNavigation;
