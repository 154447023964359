import React from 'react';
import { authenticatedUser } from 'header-scripts';
import userUtil from '../util/userUtil';

function AgeBracketDisplay() {
  const { isUnder13 } = authenticatedUser;
  const colon = ' : ';
  return (
    <div className='xsmall age-bracket-label text-header'>
      <span className='text-overflow age-bracket-label-username font-caption-header'>
        {userUtil.nameForHeader}
        {colon}
      </span>
      <span className='age-bracket-label-age'>{isUnder13 ? '<13' : '13+'}</span>
    </div>
  );
}

AgeBracketDisplay.propTypes = {};

export default AgeBracketDisplay;
